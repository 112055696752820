"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/dev-e-admin-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listAllReq = listAllReq;
exports.selectFileslist = selectFileslist;
exports.addFile = addFile;
exports.selectFileById = selectFileById;
exports.updateFile = updateFile;
exports.delFile = delFile;
exports.copyFile = copyFile;
exports.moveFile = moveFile;
exports.downFile = downFile;
exports.findTreeFiles = findTreeFiles;
exports.findTreeRepoList = findTreeRepoList;
exports.previewFile = previewFile;
exports.selectRBFileslist = selectRBFileslist;
exports.delRBFile = delRBFile;
exports.clearRB = clearRB;
exports.restoreFile = restoreFile;
exports.selectHistorylist = selectHistorylist;

var _request = _interopRequireDefault(require("@/utils/request"));

// 加载全部资料库下某文件/文件夹列表
function listAllReq(params) {
  return (0, _request.default)({
    url: "/file/api/v1/files/listAll",
    method: "get",
    params: params
  });
} // 查询资料库下某文件/文件夹列表


function selectFileslist(params) {
  return (0, _request.default)({
    url: "/file/api/v1/files",
    method: "get",
    params: params
  });
} // 添加文件夹


function addFile(data) {
  return (0, _request.default)({
    url: "/file/api/v1/files",
    method: "post",
    data: data
  });
} // 根据文件夹ID获取包含内容列表


function selectFileById(filesId) {
  return (0, _request.default)({
    url: "/file/api/v1/files/".concat(filesId),
    method: "get"
  });
} // 文件夹重命名


function updateFile(data) {
  return (0, _request.default)({
    url: "/file/api/v1/files",
    method: "put",
    data: data
  });
} // 删除文件夹/文件


function delFile(ids) {
  return (0, _request.default)({
    url: "/file/api/v1/files/logicDelete",
    method: "put",
    data: ids
  });
} // 文件操作copy


function copyFile(data) {
  return (0, _request.default)({
    url: "/file/api/v1/files/copyFiles",
    method: "put",
    data: data
  });
} // 文件操作move


function moveFile(data) {
  return (0, _request.default)({
    url: "/file/api/v1/files/moveFiles",
    method: "put",
    data: data
  });
} // 文件下载


function downFile(repoId, ids) {
  return (0, _request.default)({
    url: "/file/api/v1/private/generateDownloadUrl/".concat(repoId),
    method: "post",
    data: ids
  });
} // 查询资料库TREE下某文件/文件夹列表


function findTreeFiles(parentId, repoId) {
  return (0, _request.default)({
    url: "/file/api/v1/files/findFiles/".concat(parentId, "/").concat(repoId),
    method: "get"
  });
}

function findTreeRepoList(subjectType) {
  return (0, _request.default)({
    url: "/file/api/v1/repos/listAll",
    method: "get",
    params: {
      subjectType: subjectType
    }
  });
} // 资料库相关end
//  文件/文件夹相关begin


function previewFile(repoId, fileId) {
  return (0, _request.default)({
    url: "/file/api/v1/preview/generatePreviewUrl/".concat(repoId, "/").concat(fileId),
    method: "get"
  });
} // 查询回收站文件/文件夹列表


function selectRBFileslist(data) {
  return (0, _request.default)({
    url: "/file/api/v1/files/findRecycleBinFile",
    method: "get",
    params: data
  });
} // 删除回收站文件夹/文件


function delRBFile(ids) {
  return (0, _request.default)({
    url: "/file/api/v1/files",
    method: "delete",
    data: ids
  });
} // 清空某个资料库下的回收站


function clearRB(repoId) {
  return (0, _request.default)({
    url: "/file/api/v1/files/clearTrash/".concat(repoId),
    method: "delete"
  });
} // 还原文件/批量还原


function restoreFile(ids) {
  return (0, _request.default)({
    url: "/file/api/v1/files/recover",
    method: "put",
    data: ids
  });
} // 加载文件夹历史版本


function selectHistorylist(data) {
  return (0, _request.default)({
    url: "/file/api/v1/fileLogs",
    method: "get",
    params: data
  });
}