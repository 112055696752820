"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/dev-e-admin-youhuo/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.filter");

require("core-js/modules/es.array.find");

require("core-js/modules/es.array.map");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Folder = _interopRequireDefault(require("./components/Folder"));

var _api = require("@/views/basic/company/api");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    folder: _Folder.default
  },
  data: function data() {
    return {
      options: [],
      projectId: this.$store.state.project.projectId,
      projectName: this.$store.state.project.projectName,
      repoId: this.$store.state.project.repoId,
      breadListLast: [],
      fileInfo: {
        repoId: null,
        parentId: "0"
      }
    };
  },
  created: function created() {
    this.fileInfo.repoId = this.repoId;
    this.load();
  },
  methods: {
    toPath: function toPath(flag, id) {
      // 面包屑的点击'跳转路径'(非真跳转)
      if (flag < 0) {
        // 如果该标记等于-1说明是回收站或者修改历史,将不进行请求跳转,0是资料库首页,1是各层级文件夹
        return;
      }

      if (flag === 0) {
        this.breadListLast = [];
        this.$refs.folder.init();
      } else if (flag === 1) {
        var tempList = [];

        for (var i = 0; i < this.breadListLast.length; i++) {
          // 更新面包屑导航
          var bread = this.breadListLast[i];
          tempList.push(bread);

          if (bread.id === id) {
            this.breadListLast = tempList;
            break;
          }
        }

        if (this.fileInfo.repoId !== id) {
          this.fileInfo.parentId = id;
        } else {
          this.fileInfo.parentId = "0";
        }

        this.$refs.folder.toFilesList(this.fileInfo);
      }
    },
    // 接收子组件所传数据
    listenTochildEvent: function listenTochildEvent(val) {
      if (this.breadListLast.length <= 0 || this.breadListLast[this.breadListLast.length - 1].id !== val.id) {
        this.breadListLast.push(val);
      }
    },
    load: function load() {
      var _this = this;

      (0, _api.listAll)().then(function (res) {
        _this.options = res.data.map(function (item) {
          return Object.assign(item, {
            value: item.id,
            label: item.gsmc
          });
        });

        var obj = _this.options.find(function (item) {
          return item.id === _this.projectId;
        });

        _this.projectName = obj.gsmc;

        if (_this.projectId == null && _this.options.length > 0) {
          _this.projectId = _this.options[0].value;
          _this.projectName = _this.options[0].label;
          _this.repoId = _this.options[0].fileRepoId;

          _this.$store.commit("project/updateProject", {
            projectId: _this.projectId,
            projectName: _this.projectName,
            repoId: _this.repoId
          });
        } else if (_this.options.length < 1) {
          _this.$store.commit("project/clearProject");
        } // 当前存储的小区id  已经不存在了 删除id


        var item = _this.options.filter(function (item) {
          return item.id === _this.projectId;
        });

        if (item.length < 1) {
          _this.$store.commit("project/clearProject");
        }
      });
    },
    changeId: function changeId(id) {
      this.projectId = id;
      var obj = this.options.find(function (item) {
        return item.id === id;
      });
      console.log(obj);
      this.fileInfo.repoId = obj.fileRepoId;
      this.projectName = obj.gsmc; // this.toPath(0, '')

      this.breadListLast = [];
      this.$refs.folder.toFilesList(this.fileInfo);
    }
  }
};
exports.default = _default;