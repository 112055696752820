var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "file-container xq-auto-wrapper" },
    [
      _c("a-card", { staticClass: "box-card" }, [
        _c(
          "div",
          { staticClass: "clearfix", attrs: { slot: "title" }, slot: "title" },
          [
            _c(
              "a-row",
              [
                _c("span", [_vm._v("切换公司：")]),
                _c(
                  "a-select",
                  {
                    staticStyle: { width: "250px" },
                    attrs: { placeholder: "请选择" },
                    on: { change: _vm.changeId },
                    model: {
                      value: _vm.projectId,
                      callback: function($$v) {
                        _vm.projectId = $$v
                      },
                      expression: "projectId"
                    }
                  },
                  _vm._l(_vm.options, function(item) {
                    return _c(
                      "a-select-option",
                      { key: item.value, attrs: { value: item.value } },
                      [_vm._v(_vm._s(item.label))]
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "pt24" },
              [
                _c(
                  "a-breadcrumb",
                  { attrs: { separator: ">" } },
                  [
                    _c("a-breadcrumb-item", [
                      _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              return _vm.toPath(0, "")
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.projectName))]
                      )
                    ]),
                    _vm._l(_vm.breadListLast, function(item) {
                      return _c("a-breadcrumb-item", { key: item.id }, [
                        _c(
                          "span",
                          {
                            on: {
                              click: function($event) {
                                return _vm.toPath(1, item.id)
                              }
                            }
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      ])
                    })
                  ],
                  2
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            this.fileInfo.repoId
              ? _c("folder", {
                  ref: "folder",
                  attrs: { "file-object": _vm.fileInfo },
                  on: { listenTochildEvent: _vm.listenTochildEvent }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }