"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      json: [{
        title: "基础信息",
        item: [{
          title: "公司信息",
          path: "/basic/company"
        }, {
          title: "公司资料库",
          path: "document"
        }, {
          title: "上游客户",
          path: "/basic/customer"
        }, {
          title: "自由职业者",
          path: "/basic/freelance"
        }]
      }, {
        title: "合同管理",
        item: [{
          title: "上游合同",
          path: "/contract/customerContract"
        }, {
          title: "自由职业者合同",
          path: "/contract/freelanceContract"
        }, {
          title: "第三方合同",
          path: "/contract/other"
        }]
      }, {
        title: "任务管理",
        item: [{
          title: "上游任务管理",
          path: "task/customerTask"
        }, // {
        //   title: "平台接包管理",
        //   path: "/task/accept"
        // },
        // {
        //   title: "公司任务管理",
        //   path: "/task/manage"
        // },
        {
          title: "平台分包管理",
          path: "/task/freelanceTask"
        }]
      }, {
        title: "费用管理",
        item: [{
          title: "上游客户流水",
          path: "/cost/customerRecord"
        }, {
          title: "自由职业者流水",
          path: "/cost/freelancePayRecord"
        }]
      }, {
        title: "发票管理",
        item: [// {
        //   title: "发票抬头",
        //   path: "/bill/index"
        // },
        {
          title: "自由职业者代开",
          path: "/invoice/freelanceInvoice"
        }, {
          title: "上游开票记录",
          path: "/invoice/customerInvoice"
        }]
      }]
    };
  },
  methods: {
    goto: function goto(path) {
      this.$router.push({
        path: path
      });
    }
  }
};
exports.default = _default;